import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Smartphone, ArrowLeft, Globe, Shield, Clock, Phone } from 'lucide-react';

const AllCountries = ({ isDarkMode }) => {
  const [countries, setCountries] = useState([]);
  const [countryData, setCountryData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://backend-bice-six.vercel.app/api/all-numbers');
        const data = response.data;
        setCountries(Object.keys(data));
        setCountryData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching countries:', error);
        setError('Failed to fetch countries. Please try again later.');
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  const getFlag = (country) => {
    const flags = {
      Estonia: '🇪🇪', Finland: '🇫🇮', France: '🇫🇷', Netherlands: '🇳🇱',
      Philippines: '🇵🇭', Russia: '🇷🇺', Sweden: '🇸🇪', UK: '🇬🇧', US: '🇺🇸'
    };
    return flags[country] || '';
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (error) {
    return <div className={`text-center py-4 ${isDarkMode ? 'text-red-400' : 'text-red-500'}`}>{error}</div>;
  }

  return (
    <div className={`font-sans w-full mx-auto ${isDarkMode ? 'text-gray-200 bg-gray-900' : 'text-gray-800 bg-gray-100'}`}>
      {/* Header visible only on mobile */}
      <header className={`pt-8 fixed left-0 w-full rounded-b-2xl p-4 z-10 ${isDarkMode ? 'bg-gray-800' : 'bg-indigo-500'} md:hidden`}>
        <button
          onClick={handleBack}
          className="flex items-center justify-start text-white hover:opacity-80 transition-opacity duration-200"
        >
          <ArrowLeft size={20} />
          <span className="ml-2">Back</span>
        </button>
      </header>
      
      {/* Main content */}
      <main className="p-4 pt-20 relative min-h-screen flex justify-center items-start">
        <div className={`p-6 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg rounded-2xl font-sans w-full md:w-3/4 mx-auto ${isDarkMode ? 'text-white' : 'text-gray-800'} md:mb-10`}>
          {/* Loading spinner */}
          {loading && (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-indigo-500"></div>
            </div>
          )}

          {/* Nouveau contenu introductif */}
          <section className="mb-12">
            <h1 className="text-4xl font-bold text-center mb-6 text-indigo-600">Temporary Phone Numbers</h1>
            <div className="text-center mb-8">
              <p className="text-xl mb-4">Access instant, disposable phone numbers for SMS verification.</p>
              <p className="text-lg">Protect your privacy and streamline your online experiences.</p>
            </div>
            <div className="flex justify-center space-x-8 mb-8">
              <div className="flex items-center">
                <Phone className="text-indigo-500 mr-2" size={24} />
                <span className="font-semibold">{Object.keys(countryData).reduce((sum, country) => sum + countryData[country].length, 0)} Numbers</span>
              </div>
              <div className="flex items-center">
                <Globe className="text-indigo-500 mr-2" size={24} />
                <span className="font-semibold">{countries.length} Countries</span>
              </div>
            </div>
            <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-indigo-100'}`}>
              <h2 className="text-xl font-semibold mb-2">How to use:</h2>
              <ol className="list-decimal list-inside space-y-2">
                <li>Select a country from the list below</li>
                <li>Choose an available phone number</li>
                <li>Use it for SMS verification on any platform</li>
                <li>Receive messages in real-time on our website</li>
              </ol>
            </div>
          </section>

          {/* Grille des pays existante */}
          <div className="pt-4">
            <h2 className="text-3xl text-center font-bold mb-8 text-indigo-600">Available Countries</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {countries.map((country) => (
                <Link to={`/country/${country}`} key={country}>
                  <div
                    className={`p-4 rounded-lg shadow-md mb-4 ${
                      isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'
                    } hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1`}
                  >
                    <div className="flex flex-col">
                      <div className="flex items-center justify-between mb-2">
                        <span className="text-4xl">{getFlag(country)}</span>
                        <span className="text-xl font-semibold">{country}</span>
                      </div>
                      <div className="flex items-center justify-between mt-2">
                        <div className="flex items-center">
                          <Smartphone size={24} className="mr-2 text-indigo-500" />
                          <span className="text-sm">Available Numbers</span>
                        </div>
                        <span className="text-lg font-bold text-indigo-500">{countryData[country].length}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          {/* Le reste du contenu reste inchangé */}
          <div className="mt-16 space-y-12">
            <section className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white p-8 rounded-xl shadow-lg">
              <h2 className="text-2xl font-bold mb-6">Why Choose Our Temporary Phone Numbers?</h2>
              <ul className="space-y-4">
                {['Instant access to numbers from multiple countries', 
                  'No registration required - start receiving SMS immediately',
                  'Perfect for verifying accounts on popular platforms',
                  'Protect your privacy when signing up for online services'].map((item, index) => (
                  <li key={index} className="flex items-start">
                    <svg className="w-6 h-6 mr-2 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    {item}
                  </li>
                ))}
              </ul>
            </section>

            <section className={`p-8 rounded-xl shadow-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
              <h2 className="text-2xl font-bold mb-6 text-indigo-600">How It Works</h2>
              <ol className="space-y-4">
                {['Choose a country from the list above',
                  'Select an available phone number',
                  'Use the number for your verification needs',
                  'Receive SMS messages in real-time on our platform'].map((step, index) => (
                  <li key={index} className="flex items-start">
                    <span className="flex items-center justify-center w-8 h-8 rounded-full bg-indigo-500 text-white font-bold mr-4">{index + 1}</span>
                    {step}
                  </li>
                ))}
              </ol>
            </section>

            <section className="grid md:grid-cols-3 gap-8">
              {[
                { icon: Globe, title: 'Global Coverage', description: 'Access numbers from multiple countries to meet your international verification needs.' },
                { icon: Shield, title: 'Privacy Protection', description: 'Keep your personal phone number private when signing up for online services.' },
                { icon: Clock, title: '24/7 Availability', description: 'Our service is available round the clock, ensuring you can verify accounts anytime.' }
              ].map(({ icon: Icon, title, description }, index) => (
                <div key={index} className={`p-6 rounded-xl shadow-lg ${isDarkMode ? 'bg-gray-700' : 'bg-white'}`}>
                  <Icon size={40} className="text-indigo-500 mb-4" />
                  <h3 className="text-xl font-semibold mb-2">{title}</h3>
                  <p>{description}</p>
                </div>
              ))}
            </section>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AllCountries;

