import React from 'react';
import { Helmet } from 'react-helmet';
import Home from './Home';

const ReceiveSMSOnline = ({ isDarkMode }) => {
  return (
    <>
      <Helmet>
        <title>Receive SMS Online | Virtual Phone Numbers | FakeNumber</title>
        <meta name="description" content="Receive SMS online instantly with our virtual phone numbers. Perfect for verifications, signups, and protecting your privacy. No registration required." />
      </Helmet>
      <Home isDarkMode={isDarkMode} />
    </>
  );
};

export default ReceiveSMSOnline;