// CookieContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const CookieContext = createContext();

export const useCookies = () => useContext(CookieContext);

export const CookieProvider = ({ children }) => {
  const [cookieChoices, setCookieChoices] = useState(() => {
    const savedChoices = localStorage.getItem('cookieChoices');
    return savedChoices ? JSON.parse(savedChoices) : null;
  });

  const [showBanner, setShowBanner] = useState(!cookieChoices);

  useEffect(() => {
    if (cookieChoices) {
      localStorage.setItem('cookieChoices', JSON.stringify(cookieChoices));
    }
  }, [cookieChoices]);

  const acceptAll = () => {
    const allAccepted = {
      necessary: true,
      functional: true,
      analytics: true,
      advertising: true,
    };
    setCookieChoices(allAccepted);
    setShowBanner(false);
  };

  const rejectAll = () => {
    const allRejected = {
      necessary: true,
      functional: false,
      analytics: false,
      advertising: false,
    };
    setCookieChoices(allRejected);
    setShowBanner(false);
  };

  const customize = (choices) => {
    setCookieChoices(choices);
    setShowBanner(false);
  };

  return (
    <CookieContext.Provider value={{ cookieChoices, showBanner, acceptAll, rejectAll, customize }}>
      {children}
    </CookieContext.Provider>
  );
};

export default CookieContext;