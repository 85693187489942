import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';

const FAQ = ({ isDarkMode }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqData = [
    {
      question: "What is FakeNumber.me?",
      answer: "FakeNumber.me is a free online service that provides temporary phone numbers for receiving SMS messages. It allows users to verify accounts, sign up for services, or receive messages without using their personal phone numbers."
    },
    {
      question: "How does FakeNumber.me work?",
      answer: "Our service offers real phone numbers from various countries. When you select a number, you can view all incoming SMS messages sent to that number in real-time. These numbers are shared, so anyone can see the messages, but they're perfect for quick verifications or temporary use."
    },
    {
      question: "Is FakeNumber.me really free?",
      answer: "Yes, FakeNumber.me is completely free to use. We don't charge any fees for our service, and there are no hidden costs."
    },
    {
      question: "Can I send SMS messages using FakeNumber.me?",
      answer: "No, FakeNumber.me is a receive-only service. You can view incoming messages, but you cannot send outgoing messages from these numbers."
    },
    {
      question: "Are the phone numbers on FakeNumber.me real?",
      answer: "Yes, all the phone numbers provided by FakeNumber.me are real, active phone numbers. They can receive actual SMS messages from any sender."
    }
  ];

  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <motion.section 
      className={`mt-12 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
    >
      <h2 className={`text-3xl font-bold mb-6 text-center ${isDarkMode ? 'text-gray-200' : 'text-indigo-600'}`}>
        Frequently Asked Questions
      </h2>
      <div className="space-y-4">
        {faqData.map((item, index) => (
          <motion.div 
            key={index}
            className={`border ${isDarkMode ? 'border-gray-700 bg-gray-800' : 'border-gray-200 bg-white'} rounded-lg overflow-hidden shadow-md`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 * index }}
          >
            <button
              className={`w-full text-left p-4 focus:outline-none flex justify-between items-center ${
                openIndex === index ? (isDarkMode ? 'bg-gray-700' : 'bg-indigo-50') : ''
              }`}
              onClick={() => toggleQuestion(index)}
            >
              <span className="font-semibold">{item.question}</span>
              {openIndex === index ? (
                <ChevronUp className={isDarkMode ? 'text-gray-400' : 'text-indigo-500'} />
              ) : (
                <ChevronDown className={isDarkMode ? 'text-gray-400' : 'text-indigo-500'} />
              )}
            </button>
            <AnimatePresence>
              {openIndex === index && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <p className={`p-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    {item.answer}
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
};

export default FAQ;
