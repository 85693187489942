import React, { useState } from 'react';
import { X, Cookie, Shield, ChevronRight, Settings } from 'lucide-react';

const CookieBanner = ({ isDarkMode, onAccept, onReject, onCustomize }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [showCustomize, setShowCustomize] = useState(false);
  const [cookieChoices, setCookieChoices] = useState({
    necessary: true,
    functional: false,
    analytics: false,
    advertising: false,
  });

  const handleAccept = () => {
    setIsOpen(false);
    onAccept(cookieChoices);
  };

  const handleReject = () => {
    setIsOpen(false);
    onReject();
  };

  const handleCustomize = () => {
    setShowCustomize(!showCustomize);
  };

  const handleSaveChoices = () => {
    setShowCustomize(false);
    onCustomize(cookieChoices);
  };

  const toggleCookieChoice = (key) => {
    if (key !== 'necessary') {
      setCookieChoices(prev => ({ ...prev, [key]: !prev[key] }));
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`fixed bottom-4 left-4 right-4 md:left-8 md:right-auto md:w-96 rounded-2xl shadow-lg overflow-hidden transition-all duration-300 ease-in-out ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <Cookie className="w-8 h-8 text-blue-500 mr-2" />
            <h2 className="text-xl font-bold">Cookies Policy</h2>
          </div>
          <button onClick={handleReject} className="text-gray-500 hover:text-gray-700">
            <X className="w-6 h-6" />
          </button>
        </div>
        <p className="mb-4 text-sm">
          We use cookies to enhance your experience on fakenumber.me. By continuing to browse, you agree to our use of cookies for receiving free SMS online.
        </p>
        <div className="flex items-center mb-4 text-sm">
          <Shield className="w-4 h-4 text-green-500 mr-2" />
          <span>Your privacy is important to us</span>
        </div>
        {showCustomize && (
          <div className="mb-4">
            {Object.entries(cookieChoices).map(([key, value]) => (
              <div key={key} className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id={key}
                  checked={value}
                  onChange={() => toggleCookieChoice(key)}
                  disabled={key === 'necessary'}
                  className="mr-2"
                />
                <label htmlFor={key} className="text-sm capitalize">{key} cookies</label>
              </div>
            ))}
          </div>
        )}
        <div className="flex justify-between items-center">
          <button
            onClick={handleCustomize}
            className={`px-4 py-2 rounded-full text-sm font-medium flex items-center ${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-800'}`}
          >
            <Settings className="w-4 h-4 mr-1" />
            I set my choices
          </button>
          {showCustomize ? (
            <button
              onClick={handleSaveChoices}
              className="px-6 py-2 bg-blue-500 text-white rounded-full text-sm font-medium hover:bg-blue-600 transition-colors duration-300 flex items-center"
            >
              Save my choices
              <ChevronRight className="w-4 h-4 ml-1" />
            </button>
          ) : (
            <button
              onClick={handleAccept}
              className="px-6 py-2 bg-blue-500 text-white rounded-full text-sm font-medium hover:bg-blue-600 transition-colors duration-300 flex items-center"
            >
              Accept All
              <ChevronRight className="w-4 h-4 ml-1" />
            </button>
          )}
        </div>
      </div>
      <div className={`h-1 w-full ${isDarkMode ? 'bg-blue-400' : 'bg-blue-500'}`}></div>
    </div>
  );
};

export default CookieBanner;