import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { CookieProvider } from './CookieContext';
import CookieManager from './CookieManager';
import Home from './components/Home';
import Messages from './components/Messages';
import AllCountries from './components/AllCountries';
import CountryNumbers from './components/CountryNumbers';
import Country from './components/Country';
import Contact from './components/Contact';
import Layout from './components/Layout';
import PrivacyPage from './components/PrivacyPage';
import TermsPage from './components/TermsPage';
import About from './components/About';
import Settings from './components/Settings';
import Help from './components/Help';
import Favorites from './components/Favorites';
import MinutePhoneNumber from './components/MinutePhoneNumber';
import AnonymSMS from './components/AnonymSMS';
import FreeNumberForWhatsApp from './components/FreeNumberForWhatsApp';
import FreeSMSNumber from './components/FreeSMSNumber';
import FreeTelegramAccount from './components/FreeTelegramAccount';
import PhoneNumberGeneratorForDiscord from './components/PhoneNumberGeneratorForDiscord';
import ReceiveSMSOnline from './components/ReceiveSMSOnline';
import TempNumber from './components/TempNumber';

const routes = [
  { path: '/', component: Home },
  { path: '/privacy', component: PrivacyPage },
  { path: '/terms', component: TermsPage },
  { path: '/about', component: About },
  { path: '/contact', component: Contact },
  { path: '/minute-phone-number', component: MinutePhoneNumber },
  { path: '/anonymsms', component: AnonymSMS },
  { path: '/free-number-for-whatsapp', component: FreeNumberForWhatsApp },
  { path: '/free-sms-number', component: FreeSMSNumber },
  { path: '/free-telegram-account', component: FreeTelegramAccount },
  { path: '/phone-number-generator-for-discord', component: PhoneNumberGeneratorForDiscord },
  { path: '/receive-sms-online', component: ReceiveSMSOnline },
  { path: '/temp-number', component: TempNumber },
  // ... any other routes ...
];

function App() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });
  const [showSettings, setShowSettings] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  return (
    <CookieProvider>
      <Router>
        <AppContent 
          isDarkMode={isDarkMode} 
          toggleDarkMode={toggleDarkMode}
          showSettings={showSettings}
          setShowSettings={setShowSettings}
          showHelp={showHelp}
          setShowHelp={setShowHelp}
        />
      </Router>
    </CookieProvider>
  );
}

function AppContent({ isDarkMode, toggleDarkMode, showSettings, setShowSettings, showHelp, setShowHelp }) {
  const navigate = useNavigate();

  const openSettings = () => setShowSettings(true);
  const closeSettings = () => setShowSettings(false);

  const openHelp = () => setShowHelp(true);
  const closeHelp = () => setShowHelp(false);

  return (
    <div className={`transition-colors duration-200 ${isDarkMode ? 'dark bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      <Routes>
        <Route element={<Layout isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} openSettings={openSettings} openHelp={openHelp} />}>
          <Route path="/" element={<Home isDarkMode={isDarkMode} />} />
          <Route path="/countries" element={<AllCountries isDarkMode={isDarkMode} />} />
          <Route path="/all-countries" element={<Country />} />
          <Route path="/messages/:number" element={<Messages isDarkMode={isDarkMode} />} />
          <Route path="/contact" element={<Contact isDarkMode={isDarkMode} />} />
          <Route path="/privacy" element={<PrivacyPage isDarkMode={isDarkMode} />} />
          <Route path="/terms" element={<TermsPage isDarkMode={isDarkMode} />} />
          <Route path="/country/:countryName" element={<CountryNumbers isDarkMode={isDarkMode} />} />
          <Route path="/about" element={<About isDarkMode={isDarkMode} />} />
          <Route path="/favourites" element={<Favorites isDarkMode={isDarkMode} />} />
          <Route path="/minute-phone-number" element={<MinutePhoneNumber isDarkMode={isDarkMode} />} />
          <Route path="/anonymsms" element={<AnonymSMS isDarkMode={isDarkMode} />} />
          <Route path="/free-number-for-whatsapp" element={<FreeNumberForWhatsApp isDarkMode={isDarkMode} />} />
          <Route path="/free-sms-number" element={<FreeSMSNumber isDarkMode={isDarkMode} />} />
          <Route path="/free-telegram-account" element={<FreeTelegramAccount isDarkMode={isDarkMode} />} />
          <Route path="/phone-number-generator-for-discord" element={<PhoneNumberGeneratorForDiscord isDarkMode={isDarkMode} />} />
          <Route path="/receive-sms-online" element={<ReceiveSMSOnline isDarkMode={isDarkMode} />} />
          <Route path="/temp-number" element={<TempNumber isDarkMode={isDarkMode} />} />
        </Route>
      </Routes>
      <CookieManager isDarkMode={isDarkMode} />

      {/* Render Popups */}
      {showSettings && (
        <Settings 
          isOpen={showSettings} 
          onClose={closeSettings} 
          isDarkMode={isDarkMode} 
          navigate={(path) => {
            closeSettings();
            navigate(path);
          }}
        />
      )}
      {showHelp && (
  <Help 
    isOpen={showHelp} 
    onClose={closeHelp} 
    isDarkMode={isDarkMode} 
    navigate={(path) => {
      closeHelp();
      navigate(path);
    }}
  />
)}
    </div>
  );
}

export { routes };
export default App;



