import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Shield, Globe, Users, Clock, Lock } from 'lucide-react';
import { motion } from 'framer-motion';

const About = ({ isDarkMode }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const features = [
    { icon: <Shield size={24} />, title: "Privacy Protection", description: "We prioritize your privacy by providing temporary phone numbers." },
    { icon: <Globe size={24} />, title: "Global Coverage", description: "Access numbers from various countries around the world." },
    { icon: <Users size={24} />, title: "User-Friendly", description: "Our platform is designed for ease of use, accessible to everyone." },
    { icon: <Clock size={24} />, title: "24/7 Availability", description: "Our service is available round the clock for your convenience." },
    { icon: <Lock size={24} />, title: "Secure Platform", description: "We employ robust security measures to protect your data." }
  ];

  return (
    <div className={`font-sans w-full mx-auto ${isDarkMode ? 'text-gray-200 bg-gray-900' : 'text-gray-800 bg-gray-100'}`}>
      <header className={`pt-8 fixed left-0 w-full rounded-b-2xl p-4 z-10 ${isDarkMode ? 'bg-gray-800' : 'bg-indigo-500'} md:hidden`}>
        <button
          onClick={handleBack}
          className="flex items-center justify-start text-white hover:opacity-80 transition-opacity duration-200"
        >
          <ArrowLeft size={20} />
          <span className="ml-2">Back</span>
        </button>
      </header>

      <main className="p-4 pt-20 relative min-h-screen flex justify-center items-start">
        <motion.div 
          className={`p-4 pt-8 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} bg-opacity-75 font-sans w-full md:w-3/4 mx-auto ${isDarkMode ? 'text-white' : 'text-gray-800'} md:pt-1 md:rounded-2xl md:mb-10`}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-indigo-500"></div>
            </div>
          ) : (
            <div className="p-4">
              <h1 className="text-3xl text-center pt-5 font-semibold mb-6">About FakeNumber</h1>
              
              <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
                <p className="mb-4">
                  At FakeNumber.me, our mission is to empower users with control over their online privacy. We provide a reliable and user-friendly platform for managing digital communications without compromising personal information.
                </p>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Why Choose Us?</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {features.map((feature, index) => (
                    <motion.div 
                      key={index} 
                      className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} flex items-start`}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                      <div className={`mr-4 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}>
                        {feature.icon}
                      </div>
                      <div>
                        <h3 className="font-semibold mb-2">{feature.title}</h3>
                        <p>{feature.description}</p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>

              <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Our Commitment</h2>
                <p className="mb-4">
                  We are committed to providing a secure and reliable service. Our team continuously works on improving the platform, ensuring that you have access to the best privacy protection tools available.
                </p>
              </div>

              <div>
                <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
                <p>
                  Have questions or need assistance? We're here to help! Reach out to us at{' '}
                  <a href="mailto:support@FakeNumber.me" className="text-indigo-500 hover:underline">
                    support@FakeNumber.me
                  </a>
                  .
                </p>
              </div>
            </div>
          )}
        </motion.div>
      </main>
    </div>
  );
};

export default About;


