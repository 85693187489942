import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Home, Settings } from 'lucide-react';
import SettingsMenu from './Settings';

const AppFooter = ({ isDarkMode }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  return (
    <>
      <footer className={`fixed bottom-0 left-0 right-0 shadow-lg block md:hidden ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-600'}`}>
        <nav className="flex justify-around items-center h-16">
          <Link to="/" className={`flex flex-col items-center justify-center w-1/4 hover:text-blue-500`}>
            <Home size={24} />
            <span className="text-xs mt-1">Home</span>
          </Link>
          <Link to="/country/US" className={`flex flex-col items-center justify-center w-1/4 hover:text-blue-500`}>
            <span className="text-lg font-bold">🇺🇸</span>
            <span className="text-xs mt-1">USA</span>
          </Link>
          <Link 
              to="countries" 
             className={`flex flex-col items-center justify-center w-1/4 hover:text-blue-500`}>
             <span className="text-lg font-bold">🌎</span>
            <span className="text-xs mt-1">All Countries</span>
          </Link>
          <button onClick={toggleSettings} className={`flex flex-col items-center justify-center w-1/4 hover:text-blue-500`}>
            <Settings size={24} />
            <span className="text-xs mt-1">Settings</span>
          </button>
        </nav>
      </footer>
      <SettingsMenu isOpen={isSettingsOpen} onClose={toggleSettings} isDarkMode={isDarkMode} />
    </>
  );
};

export default AppFooter;



