import React from 'react';
import { Helmet } from 'react-helmet';
import Home from './Home';

const MinutePhoneNumber = ({ isDarkMode }) => {
  return (
    <>
      <Helmet>
        <title>10 minute phone number - Receive SMS Online for Free | FakeNumber</title>
        <meta name="description" content="Use FakeNumber to get free temporary phone numbers and receive SMS online instantly. Protect your privacy with our reliable 10 minute phone number service." />
        <meta name="keywords" content="10 minute phone number, receive sms online, free temporary phone number, online sms receive" />
        <meta property="og:title" content="10 minute phone number - Receive SMS Online for Free" />
        <meta property="og:description" content="Use FakeNumber to get free temporary phone numbers and receive SMS online instantly. Protect your privacy with our reliable 10 minute phone number service." />
        <meta name="twitter:title" content="10 minute phone number - Receive SMS Online for Free" />
        <meta name="twitter:description" content="Use FakeNumber to get free temporary phone numbers and receive SMS online instantly. Protect your privacy with our reliable 10 minute phone number service." />
      </Helmet>
      <Home isDarkMode={isDarkMode} />
    </>
  );
};

export default MinutePhoneNumber;