import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ArrowLeft, Copy, Check, RefreshCw, MessageSquare, Eye, Star, ArrowRight } from 'lucide-react';
import AdBanner from './AdBanner';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const Messages = ({ isDarkMode }) => {
  const { number } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [copied, setCopied] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const messagesPerPage = 15;
  const [suggestedNumbers, setSuggestedNumbers] = useState([]);
  const [redirecting, setRedirecting] = useState(false);

  const countryInfo = getCountryInfo(number);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`https://backend-bice-six.vercel.app/api/messages/${number}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          timeout: 10000, // 10 seconds timeout
        });

        setMessages(response.data.messages);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching messages:', error);
        setError('Failed to fetch messages. Please try again later.');
        setLoading(false);
      }
    };

    fetchMessages();

    // Check if the number is in favorites
    const favorites = JSON.parse(localStorage.getItem('favorites') || '[]');
    setIsFavorite(favorites.includes(number));
  }, [number]);

  useEffect(() => {
    const fetchSuggestedNumbers = async () => {
      try {
        const response = await axios.get('https://backend-bice-six.vercel.app/api/all-numbers');
        const data = response.data;
        const countryNumbers = data[countryInfo.name] || [];
        const filteredNumbers = countryNumbers.filter(num => num !== '+' + number).slice(0, 6);
        setSuggestedNumbers(filteredNumbers);
      } catch (error) {
        console.error('Error fetching suggested numbers:', error);
      }
    };

    fetchSuggestedNumbers();
  }, [number, countryInfo.name]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleCopy = async () => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        // For modern browsers
        await navigator.clipboard.writeText('+' + number);
      } else {
        // Fallback for older browsers and mobile devices
        const textArea = document.createElement("textarea");
        textArea.value = '+' + number;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
      }
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
      alert('Failed to copy the number. Please try again.');
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleToggleFavorite = () => {
    const favorites = JSON.parse(localStorage.getItem('favorites') || '[]');
    if (isFavorite) {
      const updatedFavorites = favorites.filter(fav => fav !== number);
      localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    } else {
      favorites.push(number);
      localStorage.setItem('favorites', JSON.stringify(favorites));
    }
    setIsFavorite(!isFavorite);
  };

  // Calculate pagination
  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = messages.slice(indexOfFirstMessage, indexOfLastMessage);
  const totalPages = Math.ceil(messages.length / messagesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSuggestedNumberClick = (suggestedNumber) => {
    setRedirecting(true);
    setTimeout(() => {
      window.location.href = `/messages/${suggestedNumber.replace('+', '')}`;
    }, 300);
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.1 } }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 }
  };

  if (error) {
    return <div className={`text-center py-4 ${isDarkMode ? 'text-red-400' : 'text-red-500'}`}>{error}</div>;
  }

  return (
    <div className={`font-sans w-full mx-auto ${isDarkMode ? 'text-gray-200 bg-gray-900' : 'text-gray-800 bg-gray-100'}`}>
      <Helmet>
        <title>Messages for +{number} | FakeNumber.me</title>
        <meta name="description" content={`View messages for the ${countryInfo.name} phone number +${number}`} />
      </Helmet>

      {/* Mobile Header */}
      <div className="md:hidden fixed top-12 left-0 w-full z-10">
        <div className={`flex flex-col items-center justify-center py-4 px-4 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-indigo-500 text-white'}`}>
          <div className="w-full flex justify-between items-center mb-2">
            <button
              onClick={handleBack}
              className="flex items-center justify-start text-white hover:opacity-80 transition-opacity duration-200"
            >
              <ArrowLeft size={20} />
              <span className="ml-2">Back</span>
            </button>
            <span className="text-4xl">{countryInfo.flag}</span>
            <button 
              onClick={handleToggleFavorite}
              className="p-1 rounded-full hover:bg-opacity-80 transition-colors duration-200"
              aria-label={isFavorite ? "Remove from favorites" : "Add to favorites"}
            >
              <Star size={24} fill={isFavorite ? "yellow" : "none"} color={isFavorite ? "yellow" : "white"} />
            </button>
          </div>
          <div className="flex items-center justify-center">
            <h1 className="text-lg font-bold">+{number}</h1>
            <button 
              onClick={handleCopy} 
              className="ml-2 p-1 rounded-full hover:bg-opacity-80 transition-colors duration-200"
              aria-label={copied ? "Number copied" : "Copy number"}
            >
              {copied ? <Check size={20} /> : <Copy size={20} />}
            </button>
          </div>
          {copied && <p className="text-sm mt-1">Number copied!</p>}
          <p className="font-bold mt-1">{countryInfo.name}</p>
        </div>
        <div className={`h-2 rounded-b-3xl ${isDarkMode ? 'bg-gray-800' : 'bg-indigo-500'}`}></div>
      </div>

      {/* Main content */}
      <main className="p-4 pt-48 md:pt-20 relative min-h-screen flex justify-center items-start">
        <motion.div 
          className={`p-4 pt-8 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} bg-opacity-75 font-sans w-full md:w-3/4 mx-auto ${isDarkMode ? 'text-white' : 'text-gray-800'} md:pt-1 md:rounded-2xl md:mb-10`}
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          {/* Desktop Header */}
          <div className="hidden md:block left-0 w-full z-10 mb-6">
            <div className={`flex items-center justify-center py-4 px-8 ${isDarkMode ? 'bg-gray-800 text-white' : 'text-gray-900'}`}>
              <span className="text-8xl mr-4">{countryInfo.flag}</span>
              <div className="flex flex-col items-start">
                <p className="text-3xl font-semibold mb-1">{countryInfo.name} Phone Number</p>
                
                <div className="flex items-center">
                  <h1 className="text-2xl font-bold">+{number}</h1>
                  <button 
                    onClick={handleCopy} 
                    className={`ml-2 p-1 rounded-full ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-indigo-400'} transition-colors duration-200`}
                    aria-label={copied ? "Number copied" : "Copy number"}
                  >
                    {copied ? <Check size={20} /> : <Copy size={20} />}
                  </button>
                  <button 
                    onClick={handleToggleFavorite}
                    className={`ml-2 p-1 rounded-full ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-indigo-400'} transition-colors duration-200`}
                    aria-label={isFavorite ? "Remove from favorites" : "Add to favorites"}
                  >
                    <Star 
                      size={20} 
                      fill={isFavorite ? "currentColor" : "none"} 
                      color={isDarkMode ? (isFavorite ? "currentColor" : "white") : (isFavorite ? "currentColor" : "black")}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center">
            {/* Loading spinner */}
            {loading && (
              <motion.div 
                className="flex justify-center items-center h-64"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-indigo-500"></div>
              </motion.div>
            )}

            {/* Render content only when not loading */}
            {!loading && (
              <>
                {/* Information Box */}
                <motion.div 
                  className={`mb-6 p-4 ${isDarkMode ? 'bg-gray-700' : 'bg-white'} rounded-lg w-full max-w-md shadow-lg`}
                  variants={itemVariants}
                >
                  <div className="flex items-center mb-4">
                    <RefreshCw className="mr-2" size={24} />
                    <p>Refresh this page to see the latest message</p>
                  </div>
                  <div className="flex items-center mb-4">
                    <MessageSquare className="mr-2" size={24} />
                    <p>Messages will be shown here in about 20 seconds</p>
                  </div>
                  <div className="flex items-center">
                    <Eye className="mr-2" size={24} />
                    <p>Anyone can see the message of this number</p>
                  </div>
                  <div className="mt-6 flex justify-center">
                    <button
                      onClick={handleRefresh}
                      className="px-4 py-2 bg-indigo-500 text-white rounded-full transition-transform duration-300 hover:bg-indigo-600 transform hover:scale-105"
                    >
                      Update most recent sms
                    </button>
                  </div>
                </motion.div>

                {/* Messages List */}
                <motion.div className="w-full" variants={itemVariants}>
                  {currentMessages.length === 0 ? (
                    <p className="text-center">No messages received yet.</p>
                  ) : (
                    <ul className="space-y-4">
                      {currentMessages.map((message, index) => (
                        <motion.li 
                          key={index} 
                          className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} max-w-full shadow-md`}
                          variants={itemVariants}
                        >
                          <div className="flex justify-between items-center mb-2">
                            <span className="font-bold">{message.from}</span>
                            <span className="text-sm text-gray-500">{message.time}</span>
                          </div>
                          <p className="whitespace-pre-wrap break-words">{message.content}</p>
                        </motion.li>
                      ))}
                    </ul>
                  )}
                </motion.div>

                {/* Pagination Controls */}
                {totalPages > 1 && (
                  <div className="flex justify-center mt-8 mb-4">
                    <nav className="inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`relative inline-flex items-center px-2 py-2 rounded-l-md border ${
                          isDarkMode ? 'border-gray-600 bg-gray-700 text-gray-300' : 'border-gray-300 bg-white text-gray-500'
                        } ${
                          currentPage === 1 
                            ? 'opacity-50 cursor-not-allowed' 
                            : 'hover:bg-gray-50 hover:text-gray-700 dark:hover:bg-gray-600 dark:hover:text-white'
                        }`}
                      >
                        <span className="sr-only">Previous</span>
                        <ArrowLeft size={20} />
                      </button>
                      {[...Array(totalPages).keys()].map((number) => (
                        <button
                          key={number + 1}
                          onClick={() => handlePageChange(number + 1)}
                          className={`relative inline-flex items-center px-4 py-2 border ${
                            isDarkMode ? 'border-gray-600 bg-gray-700 text-gray-300' : 'border-gray-300 bg-white text-gray-700'
                          } ${
                            currentPage === number + 1
                              ? isDarkMode
                                ? 'bg-gray-600 text-white'
                                : 'bg-indigo-50 text-indigo-600'
                              : 'hover:bg-gray-50 dark:hover:bg-gray-600'
                          }`}
                        >
                          {number + 1}
                        </button>
                      ))}
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className={`relative inline-flex items-center px-2 py-2 rounded-r-md border ${
                          isDarkMode ? 'border-gray-600 bg-gray-700 text-gray-300' : 'border-gray-300 bg-white text-gray-500'
                        } ${
                          currentPage === totalPages 
                            ? 'opacity-50 cursor-not-allowed' 
                            : 'hover:bg-gray-50 hover:text-gray-700 dark:hover:bg-gray-600 dark:hover:text-white'
                        }`}
                      >
                        <span className="sr-only">Next</span>
                        <ArrowRight size={20} />
                      </button>
                    </nav>
                  </div>
                )}

                {/* Add the third ad banner before the Suggested Numbers section */}
                <AdBanner
                  script={`
                    <script type="text/javascript">
                      atOptions = {
                        'key' : '8e8c16176b65ec8c0c8b580da1b5df82',
                        'format' : 'iframe',
                        'height' : 600,
                        'width' : 160,
                        'params' : {}
                      };
                    </script>
                    <script type="text/javascript" src="//fearinvulnerablediagnosis.com/8e8c16176b65ec8c0c8b580da1b5df82/invoke.js"></script>
                  `}
                />

                {/* Suggested Numbers Section */}
                <motion.div className="mt-12 w-full" variants={itemVariants}>
                  <h2 className={`text-2xl font-bold mb-6 text-center ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                    Other {countryInfo.name} Numbers
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
                    {suggestedNumbers.map((suggestedNumber, index) => (
                      <motion.div
                        key={index}
                        onClick={() => handleSuggestedNumberClick(suggestedNumber)}
                        className={`p-4 rounded-lg border border-gray-300 cursor-pointer ${
                          isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'
                        } hover:bg-opacity-80 transition-all duration-200 shadow-md`}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <div className="flex items-center justify-between mb-2">
                          <span className="text-3xl">{countryInfo.flag}</span>
                          <div className="text-right">
                            <div className="text-sm font-semibold">Phone number for {countryInfo.name}</div>
                            <div className="text-lg font-bold">{suggestedNumber}</div>
                          </div>
                        </div>
                        <div className="flex items-center justify-between mt-2">
                          <div className="flex items-center">
                            <span className="text-indigo-500 mr-2">📩</span>
                            <span className={`text-medium ${isDarkMode ? 'text-white' : 'text-black'}`}>
                              {Math.floor(Math.random() * 501) + 500}
                            </span>
                          </div>
                          <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                            {`${Math.floor(Math.random() * 60)} min ago`}
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </motion.div>

                {/* Guide Section */}
                <motion.div className="mt-12 w-full" variants={itemVariants}>
                  <h2 className={`text-2xl font-bold mb-6 text-center ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                    About this phone number in {countryInfo.name}: +{number}
                  </h2>
                  <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'} shadow-lg`}>
                    <p className="mb-4">
                      Are you looking for a free phone number in {countryInfo.name}? fakenumber.me has an incredible offer that allows you to get a free phone number in {countryInfo.name}: +{number}, without paying anything. You can get verification services via SMS and the Internet, for example, verify account registration services on Google, Facebook, Wire, Whatsapp, Instagram, etc. while hiding your real phone number.
                    </p>
                    <p className="mb-4">
                      Our service is fast, reliable and free of charge, enabling anyone wishing to obtain a phone number in {countryInfo.name} to receive SMS messages. You no longer need to subscribe to expensive support contracts or buy SIM cards for spot checks or temporary games. With our settings, you can get a {countryInfo.name} phone number immediately and start receiving messages, the whole process is simple and useful.
                    </p>
                    <p className="mb-4">
                      Visit our site now to get a free phone number in {countryInfo.name} and start receiving your SMS messages easily. Whether you need to receive important business notifications or simply want to protect your security, our services offer flexible and secure solutions. Join us and enjoy comfort, security and access to free SMS services!
                    </p>
                  </div>
                </motion.div>
              </>
            )}
          </div>
        </motion.div>
      </main>

      {redirecting && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <div className={`flex flex-col items-center text-center py-4 px-6 bg-white rounded-lg ${isDarkMode ? 'text-gray-800' : 'text-gray-800'}`}>
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            <p className="mt-4">Redirecting...</p>
          </div>
        </div>
      )}
    </div>
  );
};

// Helper function to determine country info based on phone number
const getCountryInfo = (number) => {
  const countryData = {
    '1': { name: 'US', flag: '🇺🇸' },
    '31': { name: 'Netherlands', flag: '🇳🇱' },
    '46': { name: 'Sweden', flag: '🇸🇪' },
    '372': { name: 'Estonia', flag: '🇪🇪' },
    '358': { name: 'Finland', flag: '🇫🇮' },
    '33': { name: 'France', flag: '🇫🇷' },
    '63': { name: 'Philippines', flag: '🇵🇭' },
    '7': { name: 'Russia', flag: '🇷🇺' },
    '44': { name: 'United Kingdom', flag: '🇬🇧' },
    '49': { name: 'Germany', flag: '🇩🇪' },
    '39': { name: 'Italy', flag: '🇮🇹' },
    '34': { name: 'Spain', flag: '🇪🇸' },
    '61': { name: 'Australia', flag: '🇦🇺' },
    '81': { name: 'Japan', flag: '🇯🇵' },
    '86': { name: 'China', flag: '🇨🇳' },
    '55': { name: 'Brazil', flag: '🇧🇷' },
    '91': { name: 'India', flag: '🇮🇳' },
    '52': { name: 'Mexico', flag: '🇲🇽' },
    '27': { name: 'South Africa', flag: '🇿🇦' },
  };

  for (const [prefix, info] of Object.entries(countryData)) {
    if (number.startsWith(prefix)) {
      return info;
    }
  }

  return { name: 'Unknown', flag: '🌍' };
};

export default Messages;


