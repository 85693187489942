import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Trash2, Star } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import AdBanner from './AdBanner';

const Favorites = ({ isDarkMode }) => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      const storedFavorites = JSON.parse(localStorage.getItem('favorites') || '[]');
      setFavorites(storedFavorites);
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const removeFavorite = (number) => {
    const updatedFavorites = favorites.filter(fav => fav !== number);
    setFavorites(updatedFavorites);
    localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.1 } }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 }
  };

  return (
    <div className={`font-sans w-full mx-auto ${isDarkMode ? 'text-gray-200 bg-gray-900' : 'text-gray-800 bg-gray-100'}`}>
      <Helmet>
        <title>Favorite Numbers | FakeNumber.me</title>
        <meta name="description" content="View and manage your favorite phone numbers on FakeNumber.me" />
      </Helmet>

      {/* Header visible only on mobile */}
      <header className={`pt-8 fixed left-0 w-full rounded-b-2xl p-4 z-10 ${isDarkMode ? 'bg-gray-800' : 'bg-indigo-500'} md:hidden`}>
        <button
          onClick={handleBack}
          className="flex items-center justify-start text-white hover:opacity-80 transition-opacity duration-200"
        >
          <ArrowLeft size={20} />
          <span className="ml-2">Back</span>
        </button>
        <h1 className="text-xl text-center font-bold text-white">Favorites</h1>
      </header>

      <main className="p-4 pt-28 md:pt-14 relative min-h-screen">
        <motion.div 
          className={`p-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} bg-opacity-75 font-sans w-full md:w-3/4 mx-auto ${isDarkMode ? 'text-white' : 'text-gray-800'} md:rounded-2xl md:mb-10`}
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          {/* Loading spinner */}
          {loading && (
            <motion.div 
              className="flex justify-center items-center h-64"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-indigo-500"></div>
            </motion.div>
          )}

          {/* Render content only when not loading */}
          {!loading && (
            <>
              <motion.h1 className="text-3xl text-center font-semibold mb-6" variants={itemVariants}>Favorite Numbers</motion.h1>
            
              {favorites.length === 0 ? (
                <motion.p className="text-center" variants={itemVariants}>No favorite numbers yet.</motion.p>
              ) : (
                <motion.ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4" variants={containerVariants}>
                  {favorites.map((number) => (
                    <motion.li 
                      key={number} 
                      className={`relative overflow-hidden rounded-lg shadow-md ${isDarkMode ? 'bg-gray-700' : 'bg-white'}`}
                      variants={itemVariants}
                    >
                      <div className={`absolute top-0 left-0 w-full h-1 ${isDarkMode ? 'bg-indigo-500' : 'bg-indigo-500'}`}></div>
                      <Link to={`/messages/${number}`} className="block p-4">
                        <div className="flex items-center justify-between mb-2">
                          <Star className="text-yellow-500" size={24} />
                          <span className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>Favorite</span>
                        </div>
                        <h2 className="text-xl font-bold mb-2">+{number}</h2>
                        <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                          Click to view messages
                        </p>
                      </Link>
                      <button
                        onClick={() => removeFavorite(number)}
                        className={`absolute bottom-2 right-2 p-2 rounded-full ${isDarkMode ? 'bg-gray-600 hover:bg-gray-500' : 'bg-gray-200 hover:bg-gray-300'} transition-colors duration-200`}
                        aria-label="Remove from favorites"
                      >
                        <Trash2 size={16} className={isDarkMode ? 'text-red-400' : 'text-red-500'} />
                      </button>
                    </motion.li>
                  ))}
                </motion.ul>
              )}

              {/* Ad Banner */}
              <motion.div className="mt-8" variants={itemVariants}>
                <AdBanner
                  script={`
                    <script type="text/javascript">
                      atOptions = {
                        'key' : '8e8c16176b65ec8c0c8b580da1b5df82',
                        'format' : 'iframe',
                        'height' : 600,
                        'width' : 160,
                        'params' : {}
                      };
                    </script>
                    <script type="text/javascript" src="//fearinvulnerablediagnosis.com/8e8c16176b65ec8c0c8b580da1b5df82/invoke.js"></script>
                  `}
                />
              </motion.div>

              {/* Additional Content */}
              <motion.div className="mt-12" variants={itemVariants}>
                <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                  About Favorite Numbers
                </h2>
                <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-white'} shadow-lg`}>
                  <p className="mb-4">
                    Your favorite numbers are a collection of phone numbers you've marked as important or frequently used. These numbers are easily accessible for quick reference and use.
                  </p>
                  <p className="mb-4">
                    With FakeNumber.me, you can manage your favorite numbers effortlessly. Add numbers you find useful, remove ones you no longer need, and access them quickly whenever you need to receive SMS messages or verify accounts.
                  </p>
                  <p>
                    Remember, all numbers on FakeNumber.me are free to use and don't require any personal information. Enjoy the convenience of having your most-used numbers at your fingertips!
                  </p>
                </div>
              </motion.div>

              {/* How to Use Favorite Numbers - Styled like CountryNumbers.js */}
              <motion.div className="mt-12 bg-gradient-to-r from-purple-500 to-indigo-600 rounded-lg shadow-lg overflow-hidden" variants={itemVariants}>
                <div className="p-6 sm:p-10">
                  <h2 className="text-3xl font-bold text-white mb-6">How to Use Favorite Numbers</h2>
                  <ul className="space-y-4 text-white">
                    <li className="flex items-start">
                      <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                      <span>Click on a favorite number to view its messages and details.</span>
                    </li>
                    <li className="flex items-start">
                      <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                      <span>Use the trash icon to remove a number from your favorites.</span>
                    </li>
                    <li className="flex items-start">
                      <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                      <span>Add more numbers to your favorites by clicking the star icon on the number's page.</span>
                    </li>
                    <li className="flex items-start">
                      <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                      <span>Your favorites are saved locally and will persist across sessions.</span>
                    </li>
                  </ul>
                </div>
              </motion.div>
            </>
          )}
        </motion.div>
      </main>
    </div>
  );
};

export default Favorites;