import React from 'react';
import { Helmet } from 'react-helmet';
import Home from './Home';

const TempNumber = ({ isDarkMode }) => {
  return (
    <>
      <Helmet>
        <title>Temp Number Service | FakeNumber</title>
        <meta name="description" content="Need a temp number? FakeNumber provides secure, easy-to-use temporary phone numbers for all your needs. Get a temp number instantly and receive SMS online for free." />
        <meta name="keywords" content="Temp Number, temporary phone number, FakeNumber, receive SMS online, disposable number" />
        <meta property="og:title" content="Temp Number Service | FakeNumber" />
        <meta property="og:description" content="Need a temp number? FakeNumber provides secure, easy-to-use temporary phone numbers for all your needs. Get a temp number instantly and receive SMS online for free." />
        <meta name="twitter:title" content="Temp Number Service | FakeNumber" />
        <meta name="twitter:description" content="Need a temp number? FakeNumber provides secure, easy-to-use temporary phone numbers for all your needs. Get a temp number instantly and receive SMS online for free." />
      </Helmet>
      <Home isDarkMode={isDarkMode} />
    </>
  );
};

export default TempNumber;