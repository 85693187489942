import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import FAQ from './FAQ';
import Announcement from './Announcement';
import Testimonials from './Testimonials';
import { Helmet } from 'react-helmet';
import AdBanner from './AdBanner';
import { motion } from 'framer-motion';

const PhoneNumbers = ({ isDarkMode }) => {
  const [numbers, setNumbers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchNumbers = async () => {
      try {
        const response = await axios.get('https://backend-bice-six.vercel.app/api/numbers', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          timeout: 10000,
        });

        const data = response.data;
        const filteredNumbers = {
          Sweden: data.Sweden?.slice(0, 6) || [],
          Netherlands: data.Netherlands?.slice(0, 6) || [],
          US: data.US?.slice(0, 6) || []
        };
        setNumbers(filteredNumbers);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching numbers:', error);
        setError('Failed to fetch phone numbers. Please try again later.');
        setLoading(false);
      }
    };

    fetchNumbers();
  }, []);

  const countryFlags = {
    Sweden: '🇸🇪',
    Netherlands: '🇳🇱',
    US: '🇺🇸'
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredNumbers = Object.entries(numbers).reduce((acc, [country, countryNumbers]) => {
    const matchingNumbers = countryNumbers.filter((number) =>
      number.toLowerCase().includes(searchTerm)
    );
    if (matchingNumbers.length > 0) {
      acc[country] = matchingNumbers;
    }
    return acc;
  }, {});

  return (
    <div className={`relative pb-0 ${isDarkMode ? 'bg-gray-900' : 'md:bg-[#F8F9FA] bg-indigo-600'}`}>
      <Helmet>
        <title>Receive SMS Online | Free Temp Numbers | FakeNumber</title>
        <meta name="description" content="Receive SMS online instantly with FakeNumber. Get free temporary phone numbers from USA, Sweden, Netherlands. Perfect for verifications and anonymous messaging. Try our temp number service now!" />
        <meta name="keywords" content="receive SMS online, temp number, free temporary phone number, FakeNumber, USA number, Sweden number, Netherlands number, SMS verification, anonymous messaging" />
        <meta property="og:title" content="Receive SMS Online | Free Temp Numbers | FakeNumber" />
        <meta property="og:description" content="Receive SMS online instantly with FakeNumber. Get free temporary phone numbers for verifications and anonymous messaging. Try our temp number service now!" />
        <meta name="twitter:title" content="Receive SMS Online | Free Temp Numbers | FakeNumber" />
        <meta name="twitter:description" content="Receive SMS online instantly with FakeNumber. Get free temporary phone numbers for verifications and anonymous messaging. Try our temp number service now!" />
      </Helmet>

      <AdBanner
        script={`
          <script type="text/javascript">
            atOptions = {
              'key' : '8e8c16176b65ec8c0c8b580da1b5df82',
              'format' : 'iframe',
              'height' : 600,
              'width' : 160,
              'params' : {}
            };
          </script>
          <script type="text/javascript" src="//fearinvulnerablediagnosis.com/8e8c16176b65ec8c0c8b580da1b5df82/invoke.js"></script>
        `}
      />

      <div className={`fixed left-0 right-0 z-10 pt-16 mb-4 pb-10 rounded-b-2xl px-4 ${isDarkMode ? 'bg-gray-800' : 'bg-indigo-500'} md:hidden`}>
        <input
          type="text"
          placeholder="Search phone numbers..."
          value={searchTerm}
          onChange={handleSearchChange}
          className={`w-11/12 mx-auto block p-2 rounded-lg shadow-md ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
        />
      </div>
      <div className="relative bg-transparent w-full md:w-3/4 mx-auto">
        {loading && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className={`flex flex-col items-center text-center py-4 px-6 bg-transparent ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
              <p className="mt-4">Loading...</p>
            </div>
          </div>
        )}
  
        {error && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className={`text-center py-4 ${isDarkMode ? 'text-red-400' : 'text-red-500'}`}>{error}</div>
          </div>
        )}
  
        <AdBanner
          script={`
            <script async="async" data-cfasync="false" src="//fearinvulnerablediagnosis.com/989e4e45ac733b0cf3ba30c4fd70943c/invoke.js"></script>
            <div id="container-989e4e45ac733b0cf3ba30c4fd70943c"></div>
          `}
        />

        {!loading && !error && (
          <div className={`pt-28 md:pt-4 ${isDarkMode ? 'bg-gray-900' : 'md:bg-[#F8F9FA] bg-indigo-600'}`}>
            <div className="hidden md:block p-6 mb-6">
              <h1 className={`text-3xl font-bold ${isDarkMode ? 'text-white' : 'text-indigo-500'}`}>
                Receive SMS Online with FakeNumber's Temp Number Service
              </h1>
              <p className={`mt-2 text-lg ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                Get Free Temporary Phone Numbers to Receive SMS Online 24/7<br />
                <strong>FakeNumber</strong> offers a free online platform to receive SMS messages instantly. 
                Our temp number service provides real SIM-based numbers, allowing you to view all incoming 
                messages online without using your personal phone number. Perfect for SMS verifications and 
                protecting your privacy.
              </p>
            </div>
  
            <div className={`p-4 pt-8 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} font-sans w-full mx-auto ${isDarkMode ? 'text-white' : 'text-gray-800'} md:pt-1 md:rounded-2xl ${isDarkMode ? 'md:mb-1' : 'md:mb-1'}`}>
             <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mt-4">
    {Object.entries(filteredNumbers).map(([country, countryNumbers]) =>
      countryNumbers.map((number) => (
        <Link to={`/messages/${number.replace('+', '')}`} key={number}>
          <div className={`p-4 rounded-lg border border-gray-300 text-left mb-2 ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'} hover:bg-opacity-80 transition-all duration-200`}>
            <div className="flex items-center">
              <span className="text-4xl mr-4">{countryFlags[country]}</span>
              <div className="flex-1">
                <div className="text-lg text-right mr-4 font-sm">Phone number for {country}</div>
                <div className="text-l text-right mr-4 font-bold">{number}</div>
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center">
                <span className="text-indigo-500 mr-2">📩</span>
                <span className={`text-medium ${isDarkMode ? 'text-white' : 'text-black'}`}>
                  {Math.floor(Math.random() * 501) + 500}
                </span>
              </div>
              <div className={`text-sm mr-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                {`${Math.floor(Math.random() * 60)} min ago`}
              </div>
            </div>
          </div>
        </Link>
      ))
    )}
             </div>
             <motion.section 
               className="my-12 bg-gradient-to-r from-purple-500 to-indigo-600 rounded-lg shadow-lg overflow-hidden"
               initial={{ opacity: 0, y: 20 }}
               animate={{ opacity: 1, y: 0 }}
               transition={{ delay: 0.2 }}
             >
               <div className="p-6 sm:p-10">
                 <h2 className="text-3xl font-bold text-white mb-6">
                   Why Choose FakeNumber to Receive SMS Online?
                 </h2>
                 <ul className="space-y-4 text-white">
                   <motion.li 
                     className="flex items-start"
                     initial={{ opacity: 0, x: -20 }}
                     animate={{ opacity: 1, x: 0 }}
                     transition={{ delay: 0.3 }}
                   >
                     <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                     <span>Instant access to temporary phone numbers</span>
                   </motion.li>
                   <motion.li 
                     className="flex items-start"
                     initial={{ opacity: 0, x: -20 }}
                     animate={{ opacity: 1, x: 0 }}
                     transition={{ delay: 0.4 }}
                   >
                     <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                     <span>Receive SMS online from multiple countries</span>
                   </motion.li>
                   <motion.li 
                     className="flex items-start"
                     initial={{ opacity: 0, x: -20 }}
                     animate={{ opacity: 1, x: 0 }}
                     transition={{ delay: 0.5 }}
                   >
                     <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                     <span>Perfect for app verifications and sign-ups</span>
                   </motion.li>
                   <motion.li 
                     className="flex items-start"
                     initial={{ opacity: 0, x: -20 }}
                     animate={{ opacity: 1, x: 0 }}
                     transition={{ delay: 0.6 }}
                   >
                     <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                     <span>Protect your privacy with anonymous messaging</span>
                   </motion.li>
                   <motion.li 
                     className="flex items-start"
                     initial={{ opacity: 0, x: -20 }}
                     animate={{ opacity: 1, x: 0 }}
                     transition={{ delay: 0.7 }}
                   >
                     <svg className="w-6 h-6 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                     <span>100% free temp number service</span>
                   </motion.li>
                 </ul>
               </div>
             </motion.section>
             <FAQ isDarkMode={isDarkMode} />
              <Announcement isDarkMode={isDarkMode} />
             <Testimonials isDarkMode={isDarkMode} />
             </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhoneNumbers;
