import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ArrowLeft, Smartphone, Globe, Shield, Clock } from 'lucide-react';

const CountryNumbers = ({ isDarkMode }) => {
  const { countryName } = useParams();
  const [numbers, setNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNumbers = async () => {
      try {
        const response = await axios.get('https://backend-bice-six.vercel.app/api/all-numbers');
        const data = response.data;
        setNumbers(data[countryName] || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching numbers:', error);
        setError('Failed to fetch numbers. Please try again later.');
        setLoading(false);
      }
    };

    fetchNumbers();
  }, [countryName]);

  const getFlag = (country) => {
    const flags = {
      Estonia: '🇪🇪', Finland: '🇫🇮', France: '🇫🇷', Netherlands: '🇳🇱',
      Philippines: '🇵🇭', Russia: '🇷🇺', Sweden: '🇸🇪', UK: '🇬🇧', US: '🇺🇸'
    };
    return flags[country] || '';
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (error) {
    return <div className={`text-center py-4 ${isDarkMode ? 'text-red-400' : 'text-red-500'}`}>{error}</div>;
  }

  return (
    <div className={`font-sans w-full mx-auto ${isDarkMode ? 'text-gray-200 bg-gray-900' : 'text-gray-800 bg-gray-100'}`}>
      {/* Header visible only on mobile */}
      <header className={`pt-8 fixed left-0 w-full rounded-b-2xl p-4 z-10 ${isDarkMode ? 'bg-gray-800' : 'bg-indigo-500'} md:hidden`}>
        <button
          onClick={handleBack}
          className="flex items-center justify-start text-white hover:opacity-80 transition-opacity duration-200"
        >
          <ArrowLeft size={20} />
          <span className="ml-2">Back</span>
        </button>
      </header>
      <main className="p-4 pt-20 relative min-h-screen flex justify-center items-start">
        <div className={`p-6 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg rounded-2xl font-sans w-full md:w-3/4 mx-auto ${isDarkMode ? 'text-white' : 'text-gray-800'} md:mb-10`}>
          {/* Loading spinner */}
          {loading && (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-indigo-500"></div>
            </div>
          )}

          {/* Render content only when not loading */}
          {!loading && (
            <>
              {/* Country Information Section */}
              <section className="mb-12">
                <h1 className="text-4xl font-bold text-center mb-6 text-indigo-600">
                  {getFlag(countryName)} {countryName} Phone Numbers
                </h1>
                <div className="text-center mb-8">
                  <p className="text-xl mb-4">Access instant, disposable phone numbers for SMS verification in {countryName}.</p>
                  <p className="text-lg">Choose a number below to start receiving messages.</p>
                </div>
                <div className="flex justify-center space-x-8 mb-8">
                  <div className="flex items-center">
                    <Smartphone className="text-indigo-500 mr-2" size={24} />
                    <span className="font-semibold">{numbers.length} Available Numbers</span>
                  </div>
                </div>
                <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-indigo-100'}`}>
                  <h2 className="text-xl font-semibold mb-2">How to use:</h2>
                  <ol className="list-decimal list-inside space-y-2">
                    <li>Select a phone number from the list below</li>
                    <li>Use it for SMS verification on any platform</li>
                    <li>Return to this page to view received messages</li>
                    <li>Messages are updated in real-time</li>
                  </ol>
                </div>
              </section>

              {/* Phone Numbers Grid */}
              <h2 className="text-3xl text-center font-bold mb-8 text-indigo-600">Available Numbers</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {numbers.map((number) => (
                  <Link to={`/messages/${number.replace('+', '')}`} key={number}>
                    <div className={`p-4 rounded-lg shadow-md mb-4 ${
                      isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'
                    } hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1`}>
                      <div className="flex items-center justify-between mb-2">
                        <span className="text-3xl">{getFlag(countryName)}</span>
                        <div className="text-right">
                          <div className="text-sm font-semibold">Phone number for {countryName}</div>
                          <div className="text-lg font-bold text-indigo-500">{number}</div>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mt-2">
                        <div className="flex items-center">
                          <span className="text-indigo-500 mr-2">📩</span>
                          <span className={`text-medium ${isDarkMode ? 'text-white' : 'text-black'}`}>
                            {Math.floor(Math.random() * 501) + 500}
                          </span>
                        </div>
                        <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                          {`${Math.floor(Math.random() * 60)} min ago`}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>

              {/* Additional Information Section */}
              <section className="mt-16 space-y-12">
                <div className={`p-8 rounded-xl shadow-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                  <h2 className="text-2xl font-bold mb-6 text-indigo-600">Why Use Our {countryName} Numbers?</h2>
                  <ul className="space-y-4">
                    {[
                      `Instant access to ${countryName} phone numbers`,
                      'No registration required - start receiving SMS immediately',
                      'Perfect for verifying accounts on popular platforms',
                      'Protect your privacy when signing up for online services'
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <svg className="w-6 h-6 mr-2 flex-shrink-0 text-indigo-500" fill="currentColor" viewBox="0 0 20 20">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>

                <section className="grid md:grid-cols-3 gap-8">
                  {[
                    { icon: Globe, title: 'Global Coverage', description: `Access ${countryName} numbers for international verification needs.` },
                    { icon: Shield, title: 'Privacy Protection', description: 'Keep your personal phone number private when signing up for online services.' },
                    { icon: Clock, title: '24/7 Availability', description: 'Our service is available round the clock, ensuring you can verify accounts anytime.' }
                  ].map(({ icon: Icon, title, description }, index) => (
                    <div key={index} className={`p-6 rounded-xl shadow-lg ${isDarkMode ? 'bg-gray-700' : 'bg-white'}`}>
                      <Icon size={40} className="text-indigo-500 mb-4" />
                      <h3 className="text-xl font-semibold mb-2">{title}</h3>
                      <p>{description}</p>
                    </div>
                  ))}
                </section>
              </section>
            </>
          )}
        </div>
      </main>
    </div>
  );
}

export default CountryNumbers;






