import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import announcementImg from '../assets/announcement.png';
import registrationImg from '../assets/Registration.png';
import speed3Img from '../assets/speed3.png';
import receiveImg from '../assets/receive.png';
import speedImg from '../assets/speed.png';
import speed2Img from '../assets/speed2.png';

const Announcement = ({ isDarkMode }) => {
  const [currentFeature, setCurrentFeature] = useState(0);

  const features = [
    {
      img: announcementImg,
      title: "Receive SMS Online Securely",
      content: "FakeNumber.me provides a secure platform to receive SMS messages online using temporary phone numbers. Our service ensures your privacy by preventing companies from obtaining your real phone number. Messages are displayed publicly and automatically deleted after 24 hours for enhanced security."
    },
    {
      img: registrationImg,
      title: "Free Temporary Numbers for Various Services",
      content: "Use our free temp numbers for online SMS reception on various platforms. Perfect for verifying accounts on social media, messaging apps, email services, and more without compromising your personal number."
    },
    {
      img: speed3Img,
      title: "Protect Your Privacy Online",
      content: "By using our temp numbers, you can maintain your privacy, avoid unwanted calls and messages, and reduce the personal information associated with your online accounts. It's an essential tool for protecting your digital identity."
    },
    {
      img: receiveImg,
      title: "Wide Range of Compatible Services",
      content: "Our temp numbers work with a vast array of online services, including popular social media platforms, messaging apps, dating services, email providers, and even country-specific streaming services. Stay connected without exposing your real number."
    },
    {
      img: speedImg,
      title: "Fast and Reliable SMS Reception",
      content: "Experience instant SMS reception with our service. Messages are typically received and displayed immediately. Our system regularly adds new numbers to ensure consistent availability and reliability for all users."
    },
    {
      img: speed2Img,
      title: "User-Friendly and Accessible",
      content: "Our service is designed to be easy to use and accessible to everyone. With no registration required and a simple interface, you can start receiving SMS online in just a few clicks. Visit our FAQ page for more information on how to make the most of our service."
    }
  ];

  const nextFeature = () => {
    setCurrentFeature((prev) => (prev + 1) % features.length);
  };

  const prevFeature = () => {
    setCurrentFeature((prev) => (prev - 1 + features.length) % features.length);
  };

  return (
    <motion.div 
      className={`announce rounded-xl p-4 sm:p-6 md:p-10 max-w-5xl border border-gray-300 mx-auto mt-4 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className={`text-xl sm:text-2xl font-bold text-center mb-4 sm:mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        Why Choose FakeNumber to Receive SMS Online?
      </h2>
      <p className={`text-center text-sm sm:text-base mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
        With FakeNumber's innovative temp number service, you can receive SMS online and get verification codes directly in your web browser without exposing your primary phone number. Our temporary phone numbers ensure you can skip unwanted follow-ups while protecting your privacy.
      </p>

      <div className="relative">
        <div className="flex items-center justify-center mb-4">
          <button onClick={prevFeature} className="mr-4 p-2 rounded-full bg-gray-200 text-gray-800 hover:bg-gray-300">
            <ChevronLeft size={24} />
          </button>
          <div className="w-16 h-16 mr-4">
            <img 
              src={features[currentFeature].img} 
              alt={features[currentFeature].title} 
              className="w-full h-full object-contain"
            />
          </div>
          <button onClick={nextFeature} className="ml-4 p-2 rounded-full bg-gray-200 text-gray-800 hover:bg-gray-300">
            <ChevronRight size={24} />
          </button>
        </div>
        <h3 className={`text-lg font-semibold text-center mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          {features[currentFeature].title}
        </h3>
        <p className={`text-center ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
          {features[currentFeature].content}
        </p>
      </div>

      <div className="flex justify-center mt-4">
        {features.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 mx-1 rounded-full ${
              index === currentFeature ? 'bg-blue-500' : 'bg-gray-300'
            }`}
            onClick={() => setCurrentFeature(index)}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default Announcement;


