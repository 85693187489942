import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const Country = ({ isDarkMode }) => {
  const [countryCounts, setCountryCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/all-number');
        const data = await response.json();
        
        const counts = Object.fromEntries(
          Object.entries(data).map(([country, numbers]) => [country, numbers.length])
        );
        setCountryCounts(counts);
      } catch (err) {
        console.error('Failed to fetch country numbers:', err);
        setError('Failed to load country numbers');
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <div className={`fixed inset-0 flex items-center justify-center z-50 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
        <div className="flex flex-col items-center text-center py-4 px-6">
          <div className="animate-spin rounded-full h-4 w-16 border-t-4 border-blue-500"></div>
          <p className="mt-4">Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className={`text-center py-4 ${isDarkMode ? 'text-red-400' : 'text-red-500'}`}>{error}</div>;
  }

  const getFlag = (country) => {
    switch (country) {
      case 'Estonia': return '🇪🇪';
      case 'Finland': return '🇫🇮';
      case 'France': return '🇫🇷';
      case 'Netherlands': return '🇳🇱';
      case 'Philippines': return '🇵🇭';
      case 'Russia': return '🇷🇺';
      case 'Sweden': return '🇸🇪';
      case 'UK': return '🇬🇧';
      case 'US': return '🇺🇸';
      default: return '';
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
      {/* Header */}
      <div className="fixed left-0 top-0 w-full z-10">
        <div className={`flex items-center justify-between px-4 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-indigo-500 text-white'}`}>
          <button
            onClick={handleBack}
            className="flex items-center justify-start w-auto py-2 text-white hover:opacity-80 transition-opacity duration-200"
            aria-label="Go back"
          >
            <ArrowLeft size={20} />
            <span className="ml-2">Back</span>
          </button>
          <div className="w-auto"></div>
        </div>
      </div>

      {/* Content */}
      <div className="pt-4 p-4">
        <h1 className="text-2xl font-bold mb-4">Available Countriess</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
          {Object.entries(countryCounts).map(([country, count]) => (
            <Link
              key={country}
              to={`/country/${country}`}
              className={`flex items-center rounded-lg p-4 transition-shadow shadow-lg ${
                isDarkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-gray-50'
              }`}
            >
              <span className="text-3xl mr-4">{getFlag(country)}</span>
              <div>
                <h2 className="text-xl text-right font-semibold">{country}</h2>
                <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                  {count} numbers available
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Country;