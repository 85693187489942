import React from 'react';
import { Moon, Sun } from 'lucide-react';

const AppHeader = ({ isDarkMode, toggleDarkMode }) => {
  return (
    <header className={`fixed top-0 left-0 right-0 z-50 md:hidden ${
      isDarkMode ? 'bg-gray-800 text-white' : 'bg-indigo-500 text-white'
    }`}>
      <div className="flex justify-between items-center px-4 py-5">
        <h1 className="text-lg font-semibold">Receive SMS Online</h1>
        <button onClick={toggleDarkMode} className="focus:outline-none">
          {isDarkMode ? <Sun size={24} /> : <Moon size={24} />}
        </button>
      </div>
    </header>
  );
};

export default AppHeader;