import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft, Mail, Phone, MapPin } from 'lucide-react';

const Contact = ({ isDarkMode }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      const savedScrollPosition = localStorage.getItem(`scrollPosition_${location.pathname}`);
      if (savedScrollPosition) {
        window.scrollTo(0, parseInt(savedScrollPosition, 10));
      }
    }, 500);

    const handleBeforeUnload = () => {
      localStorage.setItem(`scrollPosition_${location.pathname}`, window.pageYOffset.toString());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location.pathname]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTimeout(() => {
      setIsSubmitting(false);
      setSubmitSuccess(true);
    }, 1000);
  };

  const handleBack = () => {
    localStorage.setItem(`scrollPosition_${location.pathname}`, window.pageYOffset.toString());
    navigate(-1);
  };

  const contactInfo = [
    { icon: <Mail size={24} />, text: "support@fakenumber.me", label: "Email" },
    { icon: <Phone size={24} />, text: "+1 (555) 896-4887", label: "Phone" },
    { icon: <MapPin size={24} />, text: "1323 Columbia Blvd, Senix City, 8742", label: "Address" },
  ];

  return (
    <div className={`font-sans w-full mx-auto ${isDarkMode ? 'text-gray-200 bg-gray-900' : 'text-gray-800 bg-gray-100'}`}>
      {isSubmitting && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-300">
          <div className={`flex flex-col items-center text-center py-4 px-6 bg-transparent ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            <p className="mt-4">Submitting...</p>
          </div>
        </div>
      )}

      <header className={`pt-8 fixed left-0 w-full rounded-b-2xl p-4 z-10 ${isDarkMode ? 'bg-gray-800' : 'bg-indigo-500'} md:hidden`}>
        <button
          onClick={handleBack}
          className="flex items-center justify-start text-white hover:opacity-80 transition-opacity duration-200"
        >
          <ArrowLeft size={20} />
          <span className="ml-2">Back</span>
        </button>
      </header>

      <main className="p-4 pt-20 relative min-h-screen flex justify-center items-center">
        <div className={`p-4 pt-8 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} bg-opacity-75 font-sans w-full md:w-3/4 mx-auto ${isDarkMode ? 'text-white' : 'text-gray-800'} md:pt-1 md:rounded-2xl md:mb-10`}>
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-indigo-500"></div>
            </div>
          ) : (
            <>
              <h1 className="text-3xl text-center pt-5 font-semibold mb-4">Contact Us</h1>

              <div className="grid md:grid-cols-2 gap-8 mb-8">
                <div>
                  <h2 className="text-xl font-semibold mb-4">Get in Touch</h2>
                  <p className="mb-4">We're here to help and answer any question you might have. We look forward to hearing from you!</p>
                  
                  {contactInfo.map((info, index) => (
                    <div key={index} className="flex items-center mb-4">
                      <div className={`mr-4 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}>
                        {info.icon}
                      </div>
                      <div>
                        <p className="font-semibold">{info.label}</p>
                        <p>{info.text}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div>
                  <h2 className="text-xl font-semibold mb-4">Send Us a Message</h2>
                  {submitSuccess === true && (
                    <div className="bg-green-100 text-green-800 p-4 rounded-lg mb-4">
                      Thank you for contacting us! We will get back to you shortly.
                    </div>
                  )}

                  {submitSuccess === false && (
                    <div className="bg-red-100 text-red-800 p-4 rounded-lg mb-4">
                      Something went wrong. Please try again later.
                    </div>
                  )}

                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                      <label htmlFor="name" className="block font-semibold mb-1">Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`w-full p-2 rounded-lg border ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-gray-100 text-gray-800 border-gray-300'}`}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className="block font-semibold mb-1">Email</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`w-full p-2 rounded-lg border ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-gray-100 text-gray-800 border-gray-300'}`}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="subject" className="block font-semibold mb-1">Subject</label>
                      <input
                        type="text"
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className={`w-full p-2 rounded-lg border ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-gray-100 text-gray-800 border-gray-300'}`}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="message" className="block font-semibold mb-1">Message</label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className={`w-full p-2 rounded-lg border ${isDarkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-gray-100 text-gray-800 border-gray-300'}`}
                        rows="6"
                        required
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className={`w-full py-2 rounded-lg text-white ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-500 hover:bg-indigo-600'}`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Submitting...' : 'Send Message'}
                    </button>
                  </form>
                </div>
              </div>

              <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                <h2 className="text-xl font-semibold mb-2">Why Choose FakeNumber for Support?</h2>
                <ul className="list-disc pl-5 space-y-2">
                  <li>24/7 customer support</li>
                  <li>Quick response times</li>
                  <li>Knowledgeable and friendly staff</li>
                  <li>Comprehensive FAQ and help center</li>
                  <li>Secure and private communication channels</li>
                </ul>
              </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default Contact;
