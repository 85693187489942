import React from 'react';
import { Helmet } from 'react-helmet';
import Home from './Home';

const AnonymSMS = ({ isDarkMode }) => {
  return (
    <>
      <Helmet>
        <title>Anonymsms - Receive SMS Online for Free | Temporary Phone Numbers | FakeNumber</title>
        <meta name="description" content="FakeNumber offers free temporary phone numbers for anonymous SMS reception. Use our secure Anonymsms service to receive text messages online instantly, verify accounts, and protect your privacy. Get disposable numbers for OTP verification and more." />
        <meta name="keywords" content="Anonymsms, receive SMS online, free temporary phone number, online SMS receive, disposable phone number, virtual phone number, SMS verification, OTP receive, burner number, privacy protection" />
        <meta property="og:title" content="Anonymsms - Receive SMS Online for Free | Virtual Phone Numbers" />
        <meta property="og:description" content="Get instant access to free temporary phone numbers with FakeNumber. Our Anonymsms service lets you receive SMS online securely, perfect for account verification, protecting privacy, and avoiding spam. Try our reliable virtual numbers now!" />
        <meta name="twitter:title" content="Anonymsms - Free Online SMS Reception | Temporary Phone Numbers" />
        <meta name="twitter:description" content="Need a disposable phone number? FakeNumber provides free temporary numbers for anonymous SMS reception. Receive text messages online instantly, verify accounts safely, and maintain privacy with our trusted Anonymsms service." />
      </Helmet>
      <Home isDarkMode={isDarkMode} />
    </>
  );
};

export default AnonymSMS;