import React from 'react';
import { Link } from 'react-router-dom';
import { Moon, Sun } from 'lucide-react';
import logo from '../assets/logo.png'; // Assurez-vous que le chemin est correct

const DesktopHeader = ({ isDarkMode, toggleDarkMode }) => {
  return (
    <header className={`hidden md:flex fixed top-0 left-0 border-b border-gray-300 right-0 z-50 ${
      isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-50 text-gray-800'
    }`}>
      <div className="container mx-auto px-4 py-3 flex  justify-center items-center">
        {/* Conteneur limité à 80% de la largeur */}
        <div className="flex justify-between items-center w-3/4">
          <div className="flex items-center">
            {/* Lien vers la page d'accueil */}
            <Link to="/" className="flex items-center">
              {/* Remplacement du div de couleur par une image */}
              <img src={logo} alt="Logo" className="w-10 h-10 rounded-full mr-4" />
              <span className="text-xl font-bold">FakeNumber</span>
            </Link>
          </div>
          
          <nav className="flex items-center space-x-6">
          <a href="/" className="hover:text-indigo-500 transition-colors">HOME</a>
            <a href="/country/US" className="hover:text-indigo-500 transition-colors">USA</a>
            <a href="/country/Sweden" className="hover:text-indigo-500 transition-colors">Sweden</a>
            <a href="/countries" className="hover:text-indigo-500 transition-colors">All Countries</a>
            <a href="/Favourites" className="hover:text-indigo-500 transition-colors">Favorites </a>
            <button 
              onClick={toggleDarkMode} 
              className="focus:outline-none hover:bg-gray-200 p-2 rounded-full transition-colors"
            >
              {isDarkMode ? <Sun size={24} /> : <Moon size={24} />}
            </button>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default DesktopHeader;