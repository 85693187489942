import React from 'react';
import { HelpCircle, X, ChevronRight } from 'lucide-react';

const Help = ({ isOpen, onClose, isDarkMode, onNavigate }) => {
  if (!isOpen) return null;

  const handleNavigation = (path) => {
    onClose();
    onNavigate(path);
  };

  return (
    <>
      <div className="p-4 relative h-full">
        <button
          onClick={onClose}
          className="top-4 mb-5 right-4 bg-gray-300 rounded-full p-2 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200"
          aria-label="Close help"
        >
          <X size={20} />
        </button>
        
        <div className="bg-indigo-500 p-4 rounded-md w-[98%] mx-auto h-[38%]">
          <h1 className="text-2xl font-bold mb-4 text-white">Help</h1>
          <ul>
            {[
              { path: '/terms', label: 'Terms' },
              { path: '/privacy', label: 'Privacy' },
              { path: '/about', label: 'About' },
              { path: '/contact', label: 'Contact' },
            ].map(({ path, label }) => (
              <li key={path} className="mb-5">
                <button 
                  className="flex items-center justify-between text-white w-full hover:text-blue-200" 
                  onClick={() => handleNavigation(path)}
                >
                  <span className="flex items-center">
                    <HelpCircle className="mr-2" size={20} />
                    {label}
                  </span>
                  <ChevronRight size={20} />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Help;






