import React from 'react';

const DesktopFooter = ({ isDarkMode }) => {
  return (
    <footer className={`hidden md:flex flex-row flex-wrap ${isDarkMode ? 'bg-gray-900' : 'bg-white'} items-center justify-center w-full py-6 text-center border-t gap-y-6 gap-x-12 ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} md:justify-between sticky bottom-0 left-0 right-0`}>
      <p className={`block font-sans text-base antialiased font-normal ml-10 leading-relaxed ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
        © 2024 FakeNumber. All rights reserved.
      </p>
      <p className="flex flex-wrap items-center gap-y-2 mr-6 gap-x-8">
        <a
          href="/privacy"
          className={`block font-sans text-base antialiased font-normal leading-relaxed transition-colors ${isDarkMode ? 'text-gray-300 hover:text-blue-400' : 'text-gray-700 hover:text-blue-600'}`}
        >
          Privacy
        </a>
        <a
          href="/terms"
          className={`block font-sans text-base antialiased font-normal leading-relaxed transition-colors ${isDarkMode ? 'text-gray-300 hover:text-blue-400' : 'text-gray-700 hover:text-blue-600'}`}
        >
          Terms
        </a>
        <a
          href="/about"
          className={`block font-sans text-base antialiased font-normal leading-relaxed transition-colors ${isDarkMode ? 'text-gray-300 hover:text-blue-400' : 'text-gray-700 hover:text-blue-600'}`}
        >
          About Us
        </a>
        <a
          href="/contact"
          className={`block font-sans text-base antialiased font-normal leading-relaxed transition-colors ${isDarkMode ? 'text-gray-300 hover:text-blue-400' : 'text-gray-700 hover:text-blue-600'}`}
        >
          Contact Us
        </a>
      </p>
    </footer>
  );
};

export default DesktopFooter;