// CookieManager.js
import React from 'react';
import { useCookies } from './CookieContext';
import CookieBanner from './components/CookieBanner';

const CookieManager = ({ isDarkMode }) => {
  const { showBanner, acceptAll, rejectAll, customize } = useCookies();

  if (!showBanner) return null;

  return (
    <CookieBanner
      isDarkMode={isDarkMode}
      onAccept={acceptAll}
      onReject={rejectAll}
      onCustomize={customize}
    />
  );
};

export default CookieManager;