import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Globe, Heart, HelpCircle, X, ChevronRight } from 'lucide-react';
import Help from './Help';

const Settings = ({ isOpen, onClose, isDarkMode }) => {
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const navigate = useNavigate();

  const openHelp = () => {
    setIsHelpOpen(true);
  };

  const closeHelp = () => {
    setIsHelpOpen(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
    onClose();
  };

  if (!isOpen && !isHelpOpen) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={onClose} />

      {!isHelpOpen ? (
        <div className={`fixed top-0 left-0 w-full h-full ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'} shadow-lg z-50`}>
           <div className="p-4 relative h-full">
            <button
              onClick={onClose}
              className="top-4 mb-5 right-4 bg-gray-300 rounded-full p-2 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200"
              aria-label="Close settings"
            >
              <X size={20} />
            </button>
            <div className="bg-indigo-500 p-4  rounded-md w-[98%] mx-auto h-[30%]">
              <h1 className="text-2xl font-bold mb-4 text-white">Settings</h1>
              <ul>
                <li className="mb-5">
                  <Link 
                    to="/countries" 
                    className="flex items-center justify-between text-white hover:text-blue-200 w-full" 
                    onClick={onClose}
                  >
                    <div className="flex items-center">
                      <Globe className="mr-2" size={20} />
                      All Countries
                    </div>
                    <ChevronRight size={20} />
                  </Link>
                </li>
                <li className="mb-5">
                  <Link 
                    to="/favourites" 
                    className="flex items-center justify-between text-white hover:text-blue-200 w-full" 
                    onClick={onClose}
                  >
                    <div className="flex items-center">
                      <Heart className="mr-2" size={20} />
                      Favourites
                    </div>
                    <ChevronRight size={20} />
                  </Link>
                </li>
                <li className="mb-5">
                  <button 
                    className="flex items-center justify-between text-white hover:text-blue-200 w-full"
                    onClick={openHelp}
                  >
                    <div className="flex items-center">
                      <HelpCircle className="mr-2" size={20} />
                      Help
                    </div>
                    <ChevronRight size={20} />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className={`fixed top-0 left-0 w-full h-full ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'} shadow-lg z-50`}>
          <Help isOpen={isHelpOpen} onClose={closeHelp} isDarkMode={isDarkMode} onNavigate={handleNavigation} />
        </div>
      )}
    </>
  );
};

export default Settings;















