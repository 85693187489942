import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';

// Importez les images génératives ici
import sarahImage from '../assets/sarah-avatar.png';
import mikeImage from '../assets/mike-avatar.png';
import emilyImage from '../assets/emily-avatar.png';

const testimonials = [
  {
    name: "Sarah L.",
    role: "Freelance Designer",
    content: "FakeNumber has been a game-changer for my online privacy. I can now sign up for services without worrying about spam calls or messages. Highly recommended!",
    rating: 5,
    image: sarahImage
  },
  {
    name: "Mike R.",
    role: "Software Developer",
    content: "As a developer, I often need temporary numbers for testing. FakeNumber provides a reliable and easy-to-use service that has become an essential part of my toolkit.",
    rating: 5,
    image: mikeImage
  },
  {
    name: "Emily T.",
    role: "Online Shopper",
    content: "I love using FakeNumber for online shopping. It keeps my personal number private and I don't have to worry about marketing calls. The service is fast and efficient!",
    rating: 4,
    image: emilyImage
  }
];

const Testimonials = ({ isDarkMode }) => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const nextTestimonial = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <motion.div 
      className={`rounded-xl p-4 sm:p-6 md:p-10 max-w-5xl border border-gray-300 mx-auto mt-4 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className={`text-2xl font-bold text-center mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        What Our Users Say
      </h2>

      <div className="relative">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentTestimonial}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
            className="text-center"
          >
            <div className="mb-4 flex justify-center">
              <img 
                src={testimonials[currentTestimonial].image} 
                alt={testimonials[currentTestimonial].name}
                className="w-24 h-24 rounded-full object-cover border-4 border-blue-500"
              />
            </div>
            <p className={`text-lg italic mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
              "{testimonials[currentTestimonial].content}"
            </p>
            <h3 className={`font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {testimonials[currentTestimonial].name}
            </h3>
            <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              {testimonials[currentTestimonial].role}
            </p>
            <div className="flex justify-center mt-2">
              {[...Array(testimonials[currentTestimonial].rating)].map((_, i) => (
                <Star key={i} size={20} className="text-yellow-400 fill-current" />
              ))}
            </div>
          </motion.div>
        </AnimatePresence>

        <button 
          onClick={prevTestimonial} 
          className={`absolute left-0 top-1/2 transform -translate-y-1/2 p-2 rounded-full ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-800'} hover:bg-opacity-80`}
        >
          <ChevronLeft size={24} />
        </button>
        <button 
          onClick={nextTestimonial} 
          className={`absolute right-0 top-1/2 transform -translate-y-1/2 p-2 rounded-full ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-800'} hover:bg-opacity-80`}
        >
          <ChevronRight size={24} />
        </button>
      </div>

      <div className="flex justify-center mt-4">
        {testimonials.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 mx-1 rounded-full ${
              index === currentTestimonial ? 'bg-blue-500' : 'bg-gray-300'
            }`}
            onClick={() => setCurrentTestimonial(index)}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default Testimonials;