import React from 'react';
import { Outlet } from 'react-router-dom';
import DesktopFooter from './DesktopFooter';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import DesktopHeader from './DesktopHeader';

const Layout = ({ isDarkMode, toggleDarkMode }) => {
  return (
    <div className={`flex flex-col min-h-screen ${isDarkMode ? 'dark bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      {AppHeader && <AppHeader className="flex-shrink-0" isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />}
      {DesktopHeader && <DesktopHeader isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />}
      
      <div className="flex-grow flex flex-col md:pb-0">
        <div className="h-8 bg-gray-400 dark:bg-gray-800">
          {/* Espace vide visible entre l'en-tête et le contenu principal */}
        </div>
        <main className="flex-grow pb-16 md:pb-0 ">
          {/* pb-16 ajoute un padding en bas pour le AppFooter sur mobile */}
          <Outlet />
        </main>
      </div>
      
      {DesktopFooter && (
        <DesktopFooter 
          className="hidden md:block mt-1" 
          isDarkMode={isDarkMode} 
        />
      )}
      {AppFooter && (
        <AppFooter 
          className="md:hidden fixed bottom-0 left-0 right-0" 
          isDarkMode={isDarkMode} 
          toggleDarkMode={toggleDarkMode} 
        />
      )}
    </div>
  );
};

export default Layout;
